import React from 'react'
import axios from 'axios'
import SubstitutionList from './SubstitutionList'
import $ from 'jquery'

const textAreaStyles = {
  boxSizing: 'border-box',
  resize: 'none',
  overflowY: 'auto',
  margin: '0',
  display: 'block',
  width: '100%',
  padding: '6px',
  border: '1px solid',
  borderRadius: '4px',
  fontSize: '14px',
  fontFamily: 'sans-serif',
  height: '62px'
}

const inputStyles = {
  boxSizing: 'border-box',
  display: 'block',
  width: '100%',
  padding: '6px',
  border: '1px solid',
  borderRadius: '4px',
  background: 'white',
  lineHeight: 'inherit',
  fontSize: '14px',
  fontFamily: 'sans-serif'
}

const cardStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: '0',
  marginTop: '15px',
  marginBottom: '10px',
  padding: '10px',
  border: 'solid thin grey',
  borderRadius: '5px',
  height: 'auto',
  boxSizing: 'border-box'
}

const promptContainerStyles = {
  width: '100%',
  padding: '2px',
  marginTop: '17px',
  display: 'flex'
}

const radioButtonsContainerStyles = {
  height: '58px',
  width: '30%',
  display: 'flex'
}

const playButtonStyles = {
  fontSize: '24px',
  marginTop: '27px',
  marginLeft: '15px',
  cursor: 'pointer'
}

const radioButtonsInnerContainerStyles = {
  width: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const radioGroupStyles = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '18px'
}

const EMAIL_REQUEST_PROMPT =
  "Enter your email, we'll report back on your answers"
const EMAIL_REQUEST_PROMPT_URL =
  'https://tmt-devstore.s3.us-east-1.amazonaws.com/reportBackOnYourAnswers.mp3'
const EMAIL_REPEAT_REQUEST =
  'Please provide your email. This simulation will complete shortly.'
const EMAIL_REPEAT_REQUEST_PROMPT_URL =
  'https://tmt-devstore.s3.us-east-1.amazonaws.com/pleaseProvideYourEmail.mp3'
const CLOSING_PROMPT = 'Thank you for answering my questions.'
const CLOSING_PROMPT_URL =
  'https://tmt-devstore.s3.amazonaws.com/27435A577C052E697C0EE93DD0AD1CD8.mp3'
const FOLLOWUP_PROMPT =
  'For more information about this feedback, click on the following link:'
const PRIVACY_NOTICE =
  'The feedback provided is for general information purposes only and we make no representation or warranty of any kind, express or implied regarding the accuracy, adequacy validity, reliability or completeness of the feedback.'

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

class CreateOptions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      accountId: '',
      testName: '',
      testNameEncoded: '',
      pushURL: '',
      preamble: '',
      privacy: '',
      privacyTouched: false,
      saving: false,
      emailRequest: {},
      emailRepeat: {},
      closing: {},
      followUpPrompt: '',
      followUpPromptTouched: false,
      followUpURL: ''
    }
  }

  convertedURL = URL => {
    return URL.replaceAll(' ', '_')
  }

  componentDidMount () {
    const { modalInfo } = this.props
    axios.get(`/admin/getSettings/?testName=${modalInfo.testName}&accountId=${modalInfo.accountId}`)
      .then(settings => {
        const data = settings.data
        console.log('data: ', data)
        this.setState({
          accountId: modalInfo.accountId,
          testName: modalInfo.testName,
          testNameEncoded: this.convertedURL(modalInfo.testName),
          pushURL: data.pushURL,
          preamble: data.preamble,
          emailRequest: {
            text: data.emailRequest.text || EMAIL_REQUEST_PROMPT,
            url: data.emailRequest.url || EMAIL_REQUEST_PROMPT_URL,
            isHumanPrompt: data.emailRequest.isHumanPrompt,
            stashText: '',
            stashURL: '',
            tempId: '',
            stashTempId: ''
          },
          emailRepeat: {
            text: data.emailRepeat.text || EMAIL_REPEAT_REQUEST,
            url: data.emailRepeat.url || EMAIL_REPEAT_REQUEST_PROMPT_URL,
            isHumanPrompt: data.emailRepeat.isHumanPrompt,
            stashText: '',
            stashURL: '',
            tempId: '',
            stashTempId: ''
          },
          closing: {
            text: data.closing.text || CLOSING_PROMPT,
            url: data.closing.url || CLOSING_PROMPT_URL,
            isHumanPrompt: data.closing.isHumanPrompt,
            stashText: '',
            stashURL: '',
            tempId: '',
            stashTempId: ''
          },
          privacyTouched: data.privacyTouched,
          privacy: data.privacyTouched ? data.privacy : data.privacy|| PRIVACY_NOTICE,
          followUpPromptTouched: data.followUpPromptTouched,
          followUpPrompt: data.followUpPromptTouched ? data.followUpPrompt : data.followUpPrompt || FOLLOWUP_PROMPT,
          followUpURL: data.followUpURL
        })
        this.synchTextPrompts().then(() => {
          console.log('ready to go')
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  synchTextPrompts = async () => {
    try {
      for (const name of ['emailRequest', 'emailRepeat', 'closing']) {
        if (this.state[name].isHumanPrompt === 'simulated') {
          let response = await axios.post('/test/uploadTempUrlToS3', {
            testName: this.state.testName,
            testNameEncoded: this.state.testNameEncoded,
            accountId: this.state.accountId,
            text: this.state[name].text,
            name: name,
            tempId: ''
          })
          this.setState(prevState => ({
            [name]: { ...prevState[name], ...{ tempId: response.data.tempId } }
          }))
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleClose = async e => {
    const { hideModal, cancelHandler } = this.props
    console.log(e)
    $('.loader').css('display', 'flex')
    try {
      await axios.post('/test/deleteTempFiles', {
        accountId: this.state.accountId,
        testName: this.state.testName,
        testNameEncoded: this.state.testNameEncoded
      })
    } catch (err) {
      console.log(err)
    }
    this.setState({ saving: false })
    if (e) {
      e.preventDefault()
    } //handle calling from 'handleSubmit'
    if (cancelHandler) {
      cancelHandler()
    }
    hideModal()
  }

  handleChange = e => {
    const { name, value } = e.target
    console.log('name, value: ', name + ' ' + value)
    this.setState({
      [name]: value
    })
  }

  handleRBChange = e => {
    const { name, value } = e.target
    let tempText = this.state[name].text
    let tempURL = this.state[name].url
    let tempId = this.state[name].tempId
    this.setState({
      [name]: {
        text: this.state[name].stashText,
        url: this.state[name].stashURL,
        tempId: this.state[name].tempId,
        isHumanPrompt: value,
        stashText: tempText,
        stashURL: tempURL,
        stashTempId: tempId
      }
    })
  }

  playSound = async name => {
    console.log('inside playsound')
    let URL
    if (this.state[name].isHumanPrompt === 'simulated') {
      const { accountId, testName, testNameEncoded } = this.state
      URL = `${window.URLPrefix}${name}-${accountId}_${testNameEncoded}-${this.state[name].tempId}.mp3`
      URL = URL + '?nocache=' + new Date().getTime()
    } else {
      URL = this.state[name].text
    }
    if (URL) {
      let voicePrompt = new Audio(URL)
      voicePrompt.play().then(
        () => {
          console.log('sound played')
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  //from chatGPT 'React State Change Function'
  handlePromptChange = e => {
    console.log(e.target)
    const { name, value } = e.target
    let params = this.state[name]
    params.text = value
    this.setState(prevState => ({
      [name]: { ...prevState[name], ...params }
    }))
  }

  handlePromptBlur = async e => {
    const { name, value } = e.target
    if (this.state[name].isHumanPrompt === 'simulated') {
      let params = this.state[name]
      params.tempId = '0' //make sure playsound fails rather than playing previous sound
      this.setState(prevState => ({
        [name]: { ...prevState[name], ...params }
      }))

      try {
        let response = await axios.post(
          '/test/uploadTempUrlToS3',
          {
            testName: this.state.testName,
            testNameEncoded: this.state.testNameEncoded,
            accountId: this.state.accountId,
            text: value,
            name,
            tempId: this.state[name].tempId
          },
          axiosConfig
        )
        params.tempId = response.data.tempId
        this.setState(prevState => ({
          [name]: { ...prevState[name], ...params }
        }))
      } catch (err) {
        console.log(err)
      }
    }
  }

  createSimulatedPrompts = async () => {
    for (const name of ['emailRequest', 'emailRepeat', 'closing']) {
      if (this.state[name].isHumanPrompt === 'simulated') {
        const tempKey = `${name}-${this.state.accountId}_${this.state.testNameEncoded}-${this.state[name].tempId}.mp3`
        const finalKey = `${name}-${this.state.accountId}_${this.state.testNameEncoded}.mp3`
        try {
          await axios.post('/test/modifyPromptURL', {
            tempKey,
            finalKey
          })
          this.setState(prevState => ({
            [name]: {
              ...prevState[name],
              ...{ url: `${window.URLPrefix}${finalKey}` }
            }
          }))
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  handleSubmit = async () => {
    this.setState({ saving: true })
    $('.loader').css('display', 'flex')
    await this.createSimulatedPrompts()
    const emailRequest = {
      text: this.state.emailRequest.text,
      isHumanPrompt: this.state.emailRequest.isHumanPrompt,
      url: this.state.emailRequest.url
    }
    const emailRepeat = {
      text: this.state.emailRepeat.text,
      isHumanPrompt: this.state.emailRepeat.isHumanPrompt,
      url: this.state.emailRepeat.url
    }

    const closing = {
      text: this.state.closing.text,
      isHumanPrompt: this.state.closing.isHumanPrompt,
      url: this.state.closing.url
    }

    try {
      const data = {
        testName: this.state.testName,
        pushURL: this.state.pushURL,
        preamble: this.state.preamble,
        privacyTouched: this.state.privacy !== PRIVACY_NOTICE,
        privacy: this.state.privacy,
        followUpPromptTouched: this.state.followUpPrompt !== FOLLOWUP_PROMPT,
        followUpPrompt: this.state.followUpPrompt,
        followUpURL: this.state.followUpURL,
        emailRequest: { ...emailRequest },
        emailRepeat: { ...emailRepeat },
        closing: { ...closing }
      }

      let response = await axios.put('/admin/saveSettings', {
        accountId: this.state.accountId,
        data
      })
      console.log(response.data)
      await this.handleClose()
    } catch (err) {
      console.log(err)
    }
  }

  //available spoken human prompt url: https://s3.us-east-1.amazonaws.com/dev.humanprompts/0ab48474fc2c8610a652b2bll1qs6gp.mp3

  render () {
    return (
      <div id='modalBodyOuterContainer' style={{ marginBottom: '50px' }}>
        <div
          id='modalBodyContainer'
          className='modalBodyContainer'
          style={{ height: '500px' }}
        >
          <div id='modalBody' className='modalBody'>
            <div
              id='testNameContainer'
              style={{
                width: '100%',
                padding: '2px',
                marginTop: '17px',
                marginRight: '20px',
                fontWeight: 'bold'
              }}
            >
              Script: {this.state.testName}
            </div>
            <div style={cardStyles} id='modalBodyContent1'>
              <div className='emailReptHdng'>
                Emailed Report (modify default content)
                <hr />
              </div>
              <div
                id='preambleContainer'
                style={{
                  width: '100%',
                  marginTop: '17px'
                }}
              >
                <label>Introduction </label>
                <textarea
                  placeholder="You can provide a brief explanatory introduction to be included in each respondent's emailed report"
                  style={textAreaStyles}
                  name='preamble'
                  value={this.state.preamble}
                  rows={2}
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div
                id='privacyContainer'
                style={{
                  width: '100%',
                  padding: '2px',
                  marginTop: '17px'
                }}
              >
                <label>Privacy Notice</label>
                <textarea
                  name='privacy'
                  id='privacy'
                  style={{
                    ...textAreaStyles,
                    ...{
                      width: '100%',
                      marginBottom: '10px'
                    }
                  }}
                  value={this.state.privacy}
                  onChange={this.handleChange}
                ></textarea>
              </div>

              <label id='followUpContainer' style={{ marginTop: '17px' }}>
                Follow-up link
              </label>
              <textarea
                name='followUpPrompt'
                id='followUpPrompt'
                style={{
                  ...textAreaStyles,
                  ...{
                    width: '100%',
                    marginBottom: '10px'
                  }
                }}
                value={this.state.followUpPrompt}
                onChange={this.handleChange}
              ></textarea>
              <input
                name='followUpURL'
                id='followUpURL'
                style={{ ...inputStyles, ...{ width: '100%' } }}
                placeholder='Enter link URL here'
                value={this.state.followUpURL}
                onChange={this.handleChange}
              ></input>
            </div>

            <div className='modalBodyContainer2'>
              <div className='promptHdng'>
                Audio Prompts (replace default prompts)
                <hr />
              </div>
              <div>
                <div id='emailRequestContainer' style={promptContainerStyles}>
                  <div style={{ width: '70%' }}>
                    <label>Prompt for email entry</label>
                    <input
                      style={{ ...inputStyles, ...{ height: '40px' } }}
                      name='emailRequest'
                      value={this.state.emailRequest.text}
                      onChange={this.handlePromptChange}
                      onBlur={this.handlePromptBlur}
                    ></input>
                  </div>
                  <div style={radioButtonsContainerStyles}>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                      <i
                        className='far fa-play-circle'
                        style={playButtonStyles}
                        onClick={() => {
                          this.playSound('emailRequest')
                        }}
                      ></i>
                    </div>

                    <div
                      className='radioButtonInnerContainer'
                      style={radioButtonsInnerContainerStyles}
                    >
                      <div className='radioGroup' style={radioGroupStyles}>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='emailRequest'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.emailRequest.isHumanPrompt ===
                              'simulated'
                            }
                            value='simulated'
                            onChange={this.handleRBChange}
                          />
                          Simulated voice
                        </label>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='emailRequest'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.emailRequest.isHumanPrompt === 'human'
                            }
                            value='human'
                            onChange={this.handleRBChange}
                          />
                          Human prompt URL
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id='emailRepeatContainer' style={promptContainerStyles}>
                  <div style={{ width: '70%' }}>
                    <label>Prompt for repeating request for email</label>
                    <input
                      style={{ ...inputStyles, ...{ height: '40px' } }}
                      name='emailRepeat'
                      value={this.state.emailRepeat.text}
                      onChange={this.handlePromptChange}
                      onBlur={this.handlePromptBlur}
                    ></input>
                  </div>
                  <div style={radioButtonsContainerStyles}>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                      <i
                        className='far fa-play-circle'
                        style={playButtonStyles}
                        onClick={() => {
                          this.playSound('emailRepeat')
                        }}
                      ></i>
                    </div>
                    <div
                      className='radioButtonInnerContainer'
                      style={radioButtonsInnerContainerStyles}
                    >
                      <div className='radioGroup' style={radioGroupStyles}>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='emailRepeat'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.emailRepeat.isHumanPrompt ===
                              'simulated'
                            }
                            value='simulated'
                            onChange={this.handleRBChange}
                          />
                          Simulated voice
                        </label>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='emailRepeat'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.emailRepeat.isHumanPrompt === 'human'
                            }
                            value='human'
                            onChange={this.handleRBChange}
                          />
                          Human prompt URL
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id='emailRepeatContainer' style={promptContainerStyles}>
                  <div style={{ width: '70%' }}>
                    <label>Prompt for closing remarks</label>
                    <input
                      style={{ ...inputStyles, ...{ height: '40px' } }}
                      name='closing'
                      value={this.state.closing.text}
                      onChange={this.handlePromptChange}
                      onBlur={this.handlePromptBlur}
                    ></input>
                  </div>
                  <div style={radioButtonsContainerStyles}>
                    <div style={{ width: '20%', textAlign: 'center' }}>
                      <i
                        className='far fa-play-circle'
                        style={playButtonStyles}
                        onClick={() => {
                          this.playSound('closing')
                        }}
                      ></i>
                    </div>
                    <div
                      className='radioButtonInnerContainer'
                      style={radioButtonsInnerContainerStyles}
                    >
                      <div className='radioGroup' style={radioGroupStyles}>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='closing'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.closing.isHumanPrompt === 'simulated'
                            }
                            value='simulated'
                            onChange={this.handleRBChange}
                          />
                          Simulated voice
                        </label>
                        <label
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <input
                            type='radio'
                            name='closing'
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            checked={
                              this.state.closing.isHumanPrompt === 'human'
                            }
                            value='human'
                            onChange={this.handleRBChange}
                          />
                          Human prompt URL
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SubstitutionList
            accountId={this.state.accountId}
            testName={this.state.testName}
          />

          <div className='modalBodyContainer3'>
            <div id='pushURLContainer'>
              <div className='pushUrlHdng'>
                Push URL (webhook for data transfer)
              </div>
              <hr />
              <input
                placeholder='Enter your server URL configured to receive assessment data'
                style={inputStyles}
                name='pushURL'
                value={this.state.pushURL}
                onChange={this.handleChange}
              ></input>
            </div>
          </div>
        </div>
        <div
          id='modalFooter'
          className='modalFooter'
          style={{ boxSizing: 'border-box' }}
        >
          <button
            className='blueButton'
            id='submitButton'
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={this.handleSubmit}
            disabled={this.state.saving}
          >
            Save
          </button>
          <button
            className='blueButton'
            style={{ float: 'right' }}
            onClick={this.handleClose}
          >
            Close
          </button>
          <div className='loader'></div>
        </div>
      </div>
    )
  }
}

export default CreateOptions
