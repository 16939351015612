import React from 'react'
import classnames from 'classnames'
import TMTContentEditable from '../Common/TMTContentEditable'
import stripXMLtags from '../Common/stripXMLtags'
import $ from 'jquery'

const maxTries = 20;

class ReviewTranscriptRow extends React.Component {
  itemIDs = [];
  intervalId = 0;
  voicePrompt = null
  hold = {}

  constructor(props) {
    super(props)
    this.state = {
      prompt: '',
      response: '',
      id: '',
      URL: '',
      TIN: '',
      testItemId: ''
    }
  }

  componentDidMount() {
    const {row} = this.props
    this.setState({
      prompt: stripXMLtags(row.prompt.promptText),
      response: row.response.responseText,
      id: row.response._id,
      TIN: row.response.TIN,
      testItemId: row.response.testItemId,
      URL: row.response.URL || null
    })

    this.voicePrompt = new Audio(row.response.URL)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(prevProps.row.response.responseText !== this.props.row.response.responseText) {
  //     console.log('new data: ',this.props.row.response.responseText);
  //     this.setState({response: this.props.row.response.responseText});
  //   }
  // }

  playSound = (e) => {
    if (e.ctrlKey) {
      console.log('clicked')
    } else {
      if (this.state.response) this.voicePrompt.src = this.state.URL
      this.voicePrompt.play().then(
        () => {
          console.log('sound played')
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  handleChange = e => {
    const {readOnly, setEditingFlag} = this.props
    if (!readOnly) {
      this.setState({response: e.target.value})
      setEditingFlag(true)
    }
  }

  handleBlur = (e) => {
    const {handleResponseBlur, readOnly} = this.props
    if (!readOnly) {
      handleResponseBlur(this.state)
    }
  }

  handleFocus = () => {
    const {setFocusFlag, readOnly} = this.props
    if (!readOnly) {
      setFocusFlag(true)
    }
  }


  renderPrompt = () => {
    const {row, currentSound} = this.props

    let promptClassNames = classnames('speech-bubble-prompt', {
      active: row.prompt._id === currentSound
    })
    return (
      <div style={{display: 'flex'}}>
        <div style={{width: '5%'}}>&nbsp;</div>
        <div
          id={row.prompt._id}
          className={promptClassNames}
          style={{width: '78%'}}
        >
          {' '}
          {this.state.prompt}
        </div>
      </div>
    )
  }

  checkOnTranscription = () => {
    return new Promise((resolve, reject) => {
      let url = `/test/getItemResponse/?TIN=${this.hold.TIN}&testItemId=${this.hold.testItemId}`
      $.ajax({
        url: url,
        type: 'GET',
        success: result => {
          resolve(result)
        },
        error: err => {
          reject(err);
        }
      })
    })
  }


  startPolling = id => {
    const {checkElipseCount} = this.props
    let count = 0;
    if (!this.itemIDs.includes(id)) {
      this.itemIDs.push(id)

      this.intervalId = setInterval(() => {
        this.checkOnTranscription()
          .then(result => {
            count++;
            console.log('count: ', count, 'maxTries: ', maxTries, 'id', id, 'result: ', result);
            if (result) {
              this.setState({response: `${result}`})
              console.log('ID:', id, 'response received: ', result)
              clearInterval(this.intervalId);
              checkElipseCount()
            } else if (count > maxTries) {
              console.log('maxTries exceeded')
              clearInterval(this.intervalId);
            }
          })
          .catch(error => {
            console.error("Error checking transcription - " + id + ":", error.message);
          })
      }, 5000)
    }
  }


  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.itemIDs = [];
  }


  renderResponse = () => {
    const {row, currentSound, readOnly, dateCompleted} = this.props
    const RECENCY_LIMIT = 300000
    const elipsisAnim = `${window.MAKER_HOST}/animElipsis.gif`
    let hasResponseText = !!(
      (this.state.response && this.state.response.length) ||
      new Date().getTime() - new Date(dateCompleted).getTime() > RECENCY_LIMIT
    )
    let responseClassNames = classnames('speech-bubble-response', {
      active: row.response._id === currentSound
    })
    let cursorClass = classnames('edit-read', {active: !readOnly})
    if (
      !this.state.response &&
      new Date().getTime() - new Date(dateCompleted).getTime() < RECENCY_LIMIT
    ) {
      this.hold = {
        TIN: row.response.TIN,
        testItemId: row.response.testItemId
      }
      if (row.prompt.awaitResponse) {
        this.startPolling(row.response._id)
      }
    }
    if (this.state.URL) {
      return (
        <div style={{display: 'flex'}}>
          <div className='transcriptResponsePlay'>
            <span className='hover-control'>
              <i
                className='far fa-play-circle'
                style={{fontSize: '16px', color: 'black'}}
                onClick={this.playSound}
              ></i>
              <i
                className='fas fa-play-circle'
                style={{fontSize: '16px', color: 'black'}}
                onClick={this.playSound}
              ></i>
            </span>
          </div>
          <div className={cursorClass} style={{width: '95%'}}>
            {hasResponseText ? (
              <TMTContentEditable
                id={row.response._id}
                classes={responseClassNames}
                text={this.state.response}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                disabled={readOnly}
              />
            ) : (
              <div className='speech-bubble-response'>
                <img
                  src={elipsisAnim}
                  style={{position: 'relative', top: '3px', width: '40px'}}
                  alt=''
                ></img>
              </div>
            )}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const {row} = this.props
    return (
      <div id={row.prompt._id + 'row'} className='transcript-review-cell'>
        {this.renderPrompt()}
        {this.renderResponse()}
      </div>
    )
  }
}

export default ReviewTranscriptRow
