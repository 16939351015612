import React from 'react';

class ReviewFooterLeft extends React.Component {

  handleReset = () => {
    const {recycleModule} = this.props;
    localStorage.setItem('review_reset', 'reset');
    recycleModule();
  }

  handleScoreAll = () => {
    const {scoreAll} = this.props;
    document.body.style.cursor = 'wait';
    scoreAll();
  }

  render() {
    const {hasTable} = this.props;
    
    return (
      <div className="reviewFooterLeft">

        <button
          className="closeBtn blueButton"
          style={{marginTop: '9px', marginBottom: '8px'}}
          onClick={this.handleReset}>
          CLOSE
        </button>

        <button
          className="scoreAllBtn blueButton"
          style={{marginTop: '9px', marginBottom: '8px', marginLeft:'20px'}}
          onClick = {this.handleScoreAll}
          disabled = {!hasTable}
          >
          SHOW DETAILS
        </button>

      </div>
    )
  }
}
export default ReviewFooterLeft;

