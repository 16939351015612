import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';


const _surveyInstructions = (url, slicedTIN, responseMode) => {
  var mode = responseMode;
  console.log("mode: ", mode);
  let phoneRef = "tel:" + window.TWILIO_NUMBER;

  if (mode === undefined) {mode = "CP"} //reminders do not pass in responseMode
  return (
    <div style={{fontSize:'18pt'}}>
      <div style={{fontSytem: 'italic'}}>Instructions</div>
      <br/>
      {mode.includes("P") ?
      <div>
        <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}> To
          participate with your phone, dial:&nbsp;

          <a  style={{fontSize: '16px'}} href={phoneRef}>{window.TWILIO_NUMBER}</a>
          and key-in this number when prompted:<strong>{slicedTIN}.</strong>
          </div>
          <br></br>
          <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
            If you are using a cell phone it will help to jot this number down so
            you will have it available for key-in when you are prompted to enter it.
        </div>
        <br></br>
      </div>
       : null }
      { mode.includes("C") ?
      <div>
        <div style={{marginLeft: '20px', marginRight: '20px', fontStyle: 'italic'}}>
          To participate with your computer click

          <a href={url} target='_blank'
             rel='noopener noreferrer'>try it now.</a><br></br>
            <span>The computer you use must have speakers and a microphone, or a headset with a mic.</span>
        </div>
        <br/>
      </div>
      : null }
    </div>
  )
}

const surveyInstructions = (url, slicedTIN, responseMode) => {
  return reactElementToJSXString(_surveyInstructions(url, slicedTIN, responseMode))
}

export default surveyInstructions;