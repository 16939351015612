import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReviewRow from './ReviewRow';
import ReactTooltip from 'react-tooltip'
import $ from 'jquery';
import {setFilteredTable} from '../../actions/Review/setFilteredTable';

class ReviewTable extends React.Component {

  constructor(props) {
    super(props);
    let oldState = localStorage.getObject('reviewTable')
    if (oldState) {
      this.state = oldState;
      localStorage.removeItem('reviewTable');
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    const {
      activeRow,
      setActiveRow,
      handleRowClick,
      sortFilteredTable,
      sortCriteria,
    } = this.props;
    this.handleResize();
    let artificialEvent = {
      currentTarget: {
        id: sortCriteria
      }
    }
    sortFilteredTable(artificialEvent);

    if (activeRow) {
      $('#review-info-inner').animate({
        scrollTop: $('#' + activeRow).offset().top - 278
      }, 300);
      setActiveRow(activeRow);
      handleRowClick(activeRow);
    }
  }

  handleResize = () => {
    $('#review-info').height(window.innerHeight - 323);
  }

  adjustCSS = () => {
    $('.__react_component_tooltip').css('background-color', 'lightgoldenrodyellow').css('font-size', '16px').css('font-weight', 'normal');
  }

  tableHeader = () => {
    const {sortFilteredTable} = this.props;
    return (
      <table className="reviewTableHeader">
        <tbody>
        <tr style={{position: 'relative', left: '-5px'}}>
          <th style={{width: '3%', textAlign: 'center', cursor: 'pointer'}}>
            <ReactTooltip html={false} place="right" type="light" border={true}
                          effect="float" id="sorting-info"/>
            <i id="sorting-info"
               className="fa fa-info-circle"
               data-tip='Sort this table by clicking any of the column headings.'
               data-for="sorting-info"
               onMouseOver={this.adjustCSS}
            ></i></th>
          <th
            onClick={sortFilteredTable}
            id="colName"
            className="reviewColumnHeading"
            style={{width: '21%',}}>Name{this.displayHeading('Name')}
          </th>
          <th
            onClick={sortFilteredTable}
            id="colEmail"
            className="reviewColumnHeading"
            style={{width: '31%'}}>Email{this.displayHeading('Email')}
          </th>
          <th
            onClick={sortFilteredTable}
            id="colSurvey"
            className="reviewColumnHeading reviewHeadingNoPointer"
            style={{width: '14%'}}>Survey{this.displayHeading('Survey')}
          </th>
          <th
            onClick={sortFilteredTable}
            id="colBatch"
            className="reviewColumnHeading"
            style={{width: '15%'}}>Group/Batch{this.displayHeading('Group/Batch')}
          </th>
          <th
            onClick={sortFilteredTable}
            id="colCompleted"
            className="reviewColumnHeading"
            style={{width: '16%'}}>Completed{this.displayHeading('Completed')}
          </th>
        </tr>
        </tbody>
      </table>
    )
  }

  displayHeading = (headingText) => {
    const {activeTitle, sortDirection} = this.props;
    if (activeTitle === headingText) {
      if (sortDirection === 'asc') {
        return (<span>&nbsp;
          <i className="fa-solid fa-arrow-up"></i></span>)
      } else {
        return (<span>&nbsp;
          <i className="fa-solid fa-arrow-down"></i></span>)
      }
    } else {
      return ''
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (!localStorage.getItem('review_reset')) {
      localStorage.setObject('reviewTable', this.state);
    } else {
      localStorage.removeItem('review_reset'); //last component to unmount so remove
    }
  }

  render() {
    const {filteredTable, activeRow, handleRowClick} = this.props;
    return (
      <div>
        {this.tableHeader()}

        <div id="review-info" style={{
          overflowY: 'hidden',
          borderRight: 'solid thin gray',
          borderLeft: 'solid thin gray',
          backgroundColor: '#DBE6EE'
        }}>
          <div id='review-info-inner' style={{
            marginLeft: '20px',
            overflowY: 'auto',
            height: '99%',
            borderRight: 'solid thin transparent',
            borderBottom: 'solid thin gray',
            borderLeft: 'solid thin gray',
            borderTop: 'solid thin gray',
            backgroundColor: '#FFFFFF'
          }}>
            <table className="reviewTableBody">
              <tbody>
              {filteredTable.map((row, index) => {
                return <ReviewRow
                  activeRow={activeRow}
                  row={row}
                  key={row._id}
                  id={row._id}
                  onClick={handleRowClick}
                />
              })}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    transcript: state.transcript,
    filteredTable: state.filteredTable
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setFilteredTable
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTable);