import React from 'react';
import $ from 'jquery';
import { CSVLink } from "react-csv";
import moment from 'moment';

/* ***************  simulate click lets us use button to click hidden link rather than hyperlink *********** */
let mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element){
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  );
}

function isJSONString(str) {
  try {
      JSON.parse(str);
      return true;
  } catch (e) {
      return false;
  }
}

function jsonObjectToString(obj) {
  let result = "";
  for (const [key, value] of Object.entries(obj)) {
      result += `${key}: ${value}; `;
  }
  return result;
}

let testName = "";
let fileName = "";
let emptyRowStr = '<tr style="background-color: transparent; border: solid thin transparent; height: 25px;">' +
                      '<td class="scoreAllEmptyCell" colspan="7"></td>'+
                  '</tr>';

function getRowHTML(color,testName, TIN, respondent, email, Group_batch,dateCompleted, promptPosition,
                    itemType, assessmentType, assessmentStr, latency) {
  let thisStr =
    '<tr  style="background-color:' + color  +  ';">' +
      '<td class="scoreAllCell">' + testName + '</td>'+
      '<td class="scoreAllCell">' + TIN + '</td>'+
      '<td class="scoreAllCell">' + respondent + '</td>'+
      '<td class="scoreAllCell">' + email + '</td>'+
      '<td class="scoreAllCell">' + Group_batch + '</td>'+
      '<td class="scoreAllCell">' + dateCompleted + '</td>'+
      '<td class="scoreAllCell">' + promptPosition  +  '</td>'+
      '<td class="scoreAllCell">' + itemType + '</td>'+
      '<td class="scoreAllCell">' + assessmentType +'</td>'+
      '<td class="scoreAllCell" style="text-align: left;">' + assessmentStr.replace(/[{}"]/g, '') + '</td>'+
      '<td class="scoreAllCell">' + latency  + '</td>'+
  '</tr>';
  return thisStr;
}


class ScoreAllReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      csvData: [  //the csv-to-download header row
        ["Test Name","Test Instance","Respondent","Email","Group or Batch","Date Completed","Cumulative Score","Item Number","Item Type","Assessment Type","Assessment","Response Latency (Msec.)"],
      ]
    }
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount () {
    const { modalInfo } = this.props;

    console.log("modalInfo: ", modalInfo);

    document.body.style.cursor = 'wait';
    this.populateTable(modalInfo);
    document.body.style.cursor = 'default';
  }

  setCSVstr = (columnData) => {
    let tmpArray = this.state.csvData;
    tmpArray.push(columnData);
    this.setState({csvData: tmpArray});
  }

  populateTable = (modalInfo) => {
    testName = modalInfo.transcriptArray[0].dialog[0].prompt.testName;
    fileName = modalInfo.transcriptArray[0].dialog[0].prompt.testName;

    /* ****** sort both arrays in test-time-completed order from oldest to most recent ******* */
    let clonedTable = [...modalInfo.filteredTable];
    modalInfo.transcriptArray.sort((a, b) => new Date(a.dateCompleted) - new Date(b.dateCompleted));
    clonedTable.sort((a, b) => new Date(a.dateCompleted) - new Date(b.dateCompleted));

    /* ***************************  for each test   ***************************************** */
    Object.values(modalInfo.transcriptArray).forEach((thisTest, testIndex) => {
      $('#scoreTableBody').append(emptyRowStr);
      $('#scoreTableBody').append(
        getRowHTML("lightblue", "Test Name", "Test Instance" , "Respondent", "Email", "Group_batch",
                    "Date Completed","Cumulative Score","Item Number",
              "Item Type", "Assessment Type", "Assessment", "Response latency")
      );

      let dateCompleted = moment(thisTest.dateCompleted).format('MM/DD/YYYY h:mm A');
      let respondent =  clonedTable[testIndex].testTaker || "NA";
      let email = clonedTable[testIndex].assessmentEmail || clonedTable[testIndex].email || "NA";
      let cumulativeScore = clonedTable[testIndex].cumulativeScore || "NA";
      let Group_batch = clonedTable[testIndex].group || "NA";


      /* ***************************  for each prompt within each test  ********************** */
      Object.values(thisTest.dialog).forEach((item) => {
        let itemType = item.prompt.awaitResponse ? "question" : "instruction";

        if (itemType === "instruction") {
          $('#scoreTableBody').append(
            getRowHTML("lightgray", testName, item.assessments.TIN, respondent, email, Group_batch,dateCompleted,cumulativeScore,item.prompt.promptPosition,
                              itemType,"","",'')
          );

          this.setCSVstr([testName, item.assessments.TIN, respondent, email, Group_batch, dateCompleted,cumulativeScore,item.prompt.promptPosition,itemType," "," "," "])

        } else { //the prompt is a question -- for each type of assessment requested, create one row in the table

          let assessmentsArray = [];
          if (item && item.assessments && item.assessments.scoringAssessment)       assessmentsArray.push("keyword"      );
          if (item && item.assessments && item.assessments.chatAssessment)          assessmentsArray.push("AI"           );
          if (item && item.assessments && item.assessments.pronunciationAssessment) assessmentsArray.push("pronunciation");
          if (item && item.assessments && item.assessments.grammarAssessment)       assessmentsArray.push("grammar"      );
          if (item && item.assessments && item.assessments.sentimentAssessment)     assessmentsArray.push("sentiment"    );

          if (assessmentsArray.length) {

            /* ***************************  for each assessment for each prompt  ********************************** */
            Object.values(assessmentsArray).forEach((measure, index) => {
              let assessmentStr = "";
              let assessmentType = "";

            switch (assessmentsArray[index]) {
              case "keyword":
                let keywordObj = JSON.parse(item.assessments.scoringAssessment.toString());
                assessmentStr = "Item score: " + keywordObj.itemScore;
                break;

              case "AI":
                break;

              case "pronunciation":
                assessmentStr = item.assessments.pronunciationAssessment.toString();
                if (assessmentStr.replace(/[{}"]/g, '') === "lowScores:[]") {
                  assessmentStr = "No specific problems with pronunciation were found."
                }
                break;

              case "grammar":
                assessmentStr = item.assessments.grammarAssessment.toString();
                break;

              case "sentiment":
                assessmentStr = item.assessments.sentimentAssessment.toString();
                break;

              default:
                console.log("Not a valid assessment type");
            }

            assessmentType = assessmentsArray[index];

            //differenctiate chat with JSON object returned from chat with string returned
            if (assessmentType === "AI") {

              let JSONtype = isJSONString(item.assessments.chatAssessment)
              if (JSONtype){
                let JSONobj = JSON.parse(item.assessments.chatAssessment);
                assessmentType = "AI key-value";

                console.log("JSONobj: ", JSONobj)

                assessmentStr = jsonObjectToString(JSONobj);
              } else {
                console.log("nonJSON string: ", item.assessments.chatAssessment)
                assessmentStr = item.assessments.chatAssessment.toString();
              }

            }

            $('#scoreTableBody').append(
                getRowHTML("white", testName, item.assessments.TIN , respondent, email, Group_batch, dateCompleted, cumulativeScore, item.prompt.promptPosition,
                          itemType, assessmentType, assessmentStr, item.response.latency));

                /*  ***** finally, adjust strings before prepping for csv export  ************************* */
            let thisAssessment = assessmentStr.replace(/[{}"]/g, '');
                thisAssessment = thisAssessment.replace(/,/g, ' –');
                thisAssessment = thisAssessment.replace(/\r|\n/g, '');

                this.setCSVstr([testName, item.assessments.TIN, respondent, email, Group_batch, dateCompleted, cumulativeScore,
                                item.prompt.promptPosition,itemType,assessmentType,thisAssessment,item.response.latency])
            });

          } else { //no assessments requested

            $('#scoreTableBody').append(
                getRowHTML("white", testName, item.response.TIN , respondent, email, Group_batch,dateCompleted, cumulativeScore,
                          item.prompt.promptPosition, itemType, "None requested", "", ""));

            this.setCSVstr([testName, item.response.TIN, respondent, email, Group_batch, dateCompleted, cumulativeScore,
                            item.prompt.promptPosition,itemType,"None requested"," "," "])
          }
        assessmentsArray = [];
        }
      });
    });
  }

  handleCancel(e) {
    const {hideModal, cancelHandler} = this.props;
    e.preventDefault()
    if (cancelHandler) {
      cancelHandler();
    }
    hideModal();
  }

  downloadCSV = () => { //create this link at button click to ensure latest dataset
    let csvElement = '<CSVLink id="CSVLINK" style="color: transparent; fontZize: 0.1px;" data=' + this.state.csvData + ' filename= ' + fileName + '></CSVLink>';
    $('#modal-body').append(csvElement);
    var element = document.querySelector('#CSVLINK');
    simulateMouseClick(element);
  }

  render() {
    const {modalInfo} = this.props;

    return (
      <div>
      <CSVLink id="CSVLINK" style={{color:'transparent', fontZize:'0.1px'}} data={this.state.csvData} filename={fileName}></CSVLink>
        <div className='modal-body' style={{minHeight:'350px', maxHeight:'500px', overflow: 'auto'}}>
            <table  id='scoreTable' style={{width: '100%'}}>
                <tbody id='scoreTableBody'>
                </tbody>
            </table>
        </div>
        <div className="modal-footer" style={{padding: '0px'}}>
        <div className="btn-group pull-left" style={{margin: '5px 15px'}}>
             <button className="blueButton" style={{}}
                     onClick={this.downloadCSV}>
               Download .csv file
             </button>
         </div>
         <div className="btn-group pull-right" style={{margin: '5px 15px'}}>
             <button id="" className="blueButton"
                     onClick={this.handleCancel}>
               CLOSE
             </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ScoreAllReport;