import React from 'react';
import moment from 'moment'
import classnames from 'classnames';


class ReviewRow extends React.Component {
  onClick = (e) => {
    const {onClick} = this.props;
    onClick(e.currentTarget.id)
  }
  render() {
    const {row, id, activeRow} = this.props;
    let rowClasses = classnames('reviewRow', {active:activeRow===id})
    let email = row.assessmentEmail;
    if (!email) email = row.email;
    return (
      <tr id={id}  className={rowClasses}
          onClick={this.onClick}>
       <td className='reviewTd'
            style={{width: '22%'}}>
          {row.testTaker}</td>
        <td className='reviewTd'
            style={{width: '33%'}}>{email}</td>
        <td className='reviewTd'
            style={{width: '14%'}}>{row.testName}</td>
        <td className='reviewTd'
            style={{width: '18%'}}>{row.batch}</td>
        <td className='reviewTd'
            style={{width: '13%'}}>{moment(row.dateCompleted).format('MM/DD/YYYY')}</td>
      </tr>
    )
  }
}

export default ReviewRow;